import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import SEO from 'components/shared/SEO';
import Section from 'components/shared/Section';
import LazyImage from 'components/shared/LazyImage';
import Button from 'components/shared/Button';
import Cookies from 'components/Cookies/Cookies';

const StyledSection = styled(Section)`
  position: relative;
  height: 100vh;
  overflow: hidden;
  padding: 0 !important;
  margin-top: -80px !important;
  ${({ theme }) => theme.mq.lg} {
    margin-top: -110px !important;
  }
`;

const OuterWrapper = styled.div`
  width: 95%;
  height: 95%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background: #ee5126;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    padding: 100px;
  }
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.dark};
    top: 12px;
    left: 12px;
    z-index: -1;
  }
`;

const Subtitle = styled.p`
  font-size: 2rem;
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 3rem;
  }
`;

const Title = styled.h1`
  font-size: 2.8rem;
  margin: 20px 0;
  ${({ theme }) => theme.mq.xs} {
    font-size: 3.6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 6rem;
    font-size: 4.4rem;
    margin: 30px 0;
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: 6rem;
  }
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  ${({ theme }) => theme.mq.s} {
    padding: 20px;
    font-size: ${({ theme }) => theme.fontSize.s};
    &:before,
    &:after {
      width: 50px;
    }
  }
`;

const NotFoundPage = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  return (
    <>
      <SEO title="Nie znaleziono strony" />
      <main>
        <StyledSection>
          <LazyImage src={heroImg} alt="background" cover />
          <OuterWrapper>
            <Wrapper>
              <Subtitle>404</Subtitle>
              <Title>Nie znaleziono strony</Title>
              <StyledButton as={Link} to="/">
                Strona główna
              </StyledButton>
            </Wrapper>
          </OuterWrapper>
        </StyledSection>
      </main>
      <Cookies />
    </>
  );
};

NotFoundPage.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export const query = graphql`
  query queryNotFoundPage {
    heroImg: file(name: { eq: "background" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default NotFoundPage;
